import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader, FlexboxGrid } from 'rsuite';

import {
  StepHeaderContainer,
  CreateAccountTitle,
  StepIndicator,
  StepCircle,
  MobileImage,
  MobileImageContent,
  CancelButton,
} from './dinabitesignup.style';
import {
  Container,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
  DinabiteText,
} from '../DinabiteSignupPanel/globaldinabite.style';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import welcomeImage from '../../assets/images/Illustration (2).png';
import welcomeImage2 from '../../assets/images/5492288.png';
import toolsimage from '../../assets/images/333.png';
import well from '../../assets/images/wellcome.png';
import well2 from '../../assets/images/naee.png';
import { getTitleForStep, getTextForStep } from '../steputils/steputils';
import { useAuth } from '../../context/AuthContext';
import useUser from '../../hooks/useUser';
import { createUserSocial, createCompany } from '../../services/createUser';
import {
  INITIAL_VALUES_PROVIDER,
  LOGIN_PROVIDER_KEY,
} from '../../lib/globalValues';
import {
  notificationWithNodes,
  notification,
  notificationError,
} from '../../lib/notification';
import { sendverifyingEmail } from '../../services/sendverifyingemail';
import Step0 from './step0';
import Modal from '../DinabiteSignin/modal';

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
  address?: string;
  street?: string;
  postcode?: string;
  city?: string;
  zipCode?: string; // New field
  phone?: string; // New field
}

interface SignupLocationState {
  fromLogin: boolean;
  currentStep2?: number;
  email: string;
  password?: string; // Make this optional if it's not always provided
}
interface SignupLocationState {
  activationform: boolean; // Indicates if this navigation is from account activation
  currentStep2?: number; // Optional, can be used to set the current step in the form
}

interface CreateCompanyRequest {
  email: string; // Required
  name: string; // Required
  country: string; // Required
  address: string; // Required
  city: string; // Required
  zipCode: string; // Required
  phone: string; // Required
  website: string;
}

const DinabiteLogin = () => {
  const { loginSocial, login2 } = useUser();
  const history = useHistory();
  const { setUser, loginProvider, setLoginProvider, setCompanySelected } =
    useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [gmailAccount, setGmailAccount] = useState(null);
  const { provider, userID, tokenID } = loginProvider;
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const location = useLocation<SignupLocationState>();
  const [emaillogin, setEmaillogin] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState(''); // State for modal email
  const [isLoadingg, setIsLoadingg] = useState(false);

  const clearStorage = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
  };
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: '',
    name: '',
    accountType: 'EMAIL',
    address: '', // Add new fields
    street: '',
    postcode: '',
    city: '',
    agreeToTerms: false,
    website: '',
  });
  const initialFormValues = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: '',
    name: '',
    accountType: 'EMAIL',
    address: '',
    street: '',
    postcode: '',
    city: '',
    agreeToTerms: false,
    website: '',
  };
  const resetForm = () => {
    setFormValues(initialFormValues); // Reset to initial values
  };
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {

  //   const userEmail = loginProvider?.data?.email;

  //   if (!userEmail) {
  //     const loginProviderKey = localStorage.getItem(LOGIN_PROVIDER_KEY);
  //     localStorage.removeItem(LOGIN_PROVIDER_KEY);
  //     if (loginProviderKey) {
  //       try {
  //         const retrievedLoginProvider = JSON.parse(loginProviderKey);
  //         setLoginProvider(retrievedLoginProvider);

  //       } catch (error) {
  //         // console.error('Error parsing loginProviderKey:', error);
  //       }
  //     } else {
  //       // console.log('No loginProviderKey found in localStorage.');
  //     }
  //   } else {
  //     const stepFromURL = parseInt(
  //       new URLSearchParams(window.location.search).get('step') || '2',
  //       10,
  //     );
  //     if (stepFromURL) {
  //       setCurrentStep(2);
  //       // setCurrentStep(stepFromURL);
  //     }
  //   }
  // }, [loginProvider, setLoginProvider]);
  useEffect(() => {
    if (loginProvider?.data) {
      const userEmail = loginProvider.data.email;

      if (userEmail) {
        const stepFromURL = parseInt(
          new URLSearchParams(window.location.search).get('step') || '2',
          10,
        );
        setCurrentStep(stepFromURL || 2);
      }
    } else {
      const loginProviderKey = localStorage.getItem(LOGIN_PROVIDER_KEY);
      localStorage.removeItem(LOGIN_PROVIDER_KEY);
      if (loginProviderKey) {
        try {
          const retrievedLoginProvider = JSON.parse(loginProviderKey);
          setLoginProvider(retrievedLoginProvider);
        } catch (error) {
          // Handle JSON parse error
        }
      } else {
        // console.log('No loginProviderKey found in localStorage.');
      }
    }
  }, [loginProvider, setLoginProvider]);

  // useEffect(() => {
  //   if (location.state) {
  //     if (location.state.fromLogin) {
  //       setEmaillogin(location.state.email || '');
  //       setCurrentStep(location.state.currentStep || 0);
  //     }
  //   }
  // }, [location]);

  //   useEffect(() => {
  //     if (location.state) {
  //       if (location.state.activationform&&location.state.email ) {
  //         setEmaillogin(location.state.email || '');
  //         setCurrentStep(location.state.currentStep || 0);
  //       }
  //       else{
  //         setCurrentStep(0);
  //       }
  //     }

  //   }, [location]);
//   const storage = (data) => {
//     console.log('Storing data:', data); // Check what's being passed
//     localStorage.setItem('user', JSON.stringify(data.user));
//     localStorage.setItem('access_token', data.access_token);
// };

  useEffect(() => {
    // Default case when no relevant state is found
    // const user = localStorage.getItem('user');
    // console.log("coming from login user",user)
    // const accessToken = localStorage.getItem('access_token');


    const user = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');
    if (user) {
      const parsedUser = JSON.parse(user);
      // console.log('user in localStorageeeeeee:', parsedUser);

      // Destructure the email from the parsed user object
      const { email } = parsedUser;

      if (email) {
        console.log('User email:', email);
        setEmaillogin(email);
      } else {
        setCurrentStep(0);
        console.log('Email not found in user object.');
      }
    } else {
      // setCurrentStep(0);
    }
    console.log('Location State:', location.state);

    if (location.state) {
      console.log('1');
      const { currentStep2, email, fromLogin } = location.state;
      console.log('Current Step:', currentStep2); // Logs: 2
      console.log('Email:', email); // Logs: "ba@yopmail.com"
      console.log('From Login:', fromLogin); // Logs: true

      // If activationform is true and email exists in state
      if (location.state.activationform && location.state.email) {
        console.log('check 1');
        setEmaillogin(location.state.email || '');
        setCurrentStep(
          location.state.currentStep2 !== undefined
            ? location.state.currentStep2
            : 0,
        );
      }
      // If fromLogin is true but activationform is not present
      else if (location.state.fromLogin) {
        console.log('check 2');

        setEmaillogin(location.state.email || '');
        setCurrentStep(
          location.state.currentStep2 !== undefined
            ? location.state.currentStep2
            : 0,
        );
        
      
      }
    }
  }, [location]);

  const handleCountryChange = useCallback((country: string) => {
    setSelectedCountry(country);
  }, []);

  const handleNextStep = async (values: FormValues) => {
    const latestValues = { ...formValues, ...values };
    setFormValues(latestValues);
    if (currentStep === 0) {
      setCurrentStep(1);
      return;
    }

    if (currentStep === 1) {
      setIsLoadingg(true);
      // setIsLoading(false); // Stop loading

      const {
        email,
        password,
        firstName = '', // Default to empty string if undefined
        lastName = '', // Default to empty string if undefined
        companyName = '', // Default to empty string if undefined
        country,
        accountType,
      } = latestValues;

      const updatedValues = {
        email,
        password,
        firstName,
        lastName,
        name: latestValues.name || companyName || 'none', // Use name or companyName
        country: selectedCountry || 'United States', // Use selectedCountry for country
        accountType: 'email',
      };

      console.log('Updated values:', updatedValues);

      try {
        console.log('step: Calling createUserSocial');

        const response2 = await createUserSocial(updatedValues, () => {
          // Success callback - Optional: Add notifications here if needed
        });

        // Handle the response as needed
        if (response2.data) {
          console.log(
            'step: Inside createUserSocial - User created successfully',
          );
          // setIsLoadingg(true);
          setShowModal(true);
          setModalEmail(email); // Set email for modal
          setModalMessage(
            'For activation of your account, a verification email has been sent to you.',
          );
          // Optional: Send verification email if needed
          const emailResponse = await sendverifyingEmail(email);
          if (emailResponse?.data) {
            // Show success notification for email sent
            // notification({
            //   type: 'success',
            //   title: 'Email Sent',
            //   description:
            //     'A verification email has been sent. Please check your email.',
            // });
            resetForm();
            setIsLoading(false);
            setIsLoadingg(false);
          } else {
            // Handle failure to send verification email
            notificationError({
              title: 'Error',
              description:
                'There was a problem sending the verification email.',
            });
            resetForm();
            setIsLoading(false);
            setIsLoadingg(false);
          }
        } else {
          console.log('step: Error occurred in createUserSocial');
          notificationError({
            title: 'Error',
            description: 'Failed to create user account. Please try again.',
          });
          setIsLoading(false);
          setIsLoadingg(false);
        }
      } catch (error) {
        console.error('Error during user creation:', error);
        notificationError({
          title: 'Error',
          description: 'An unexpected error occurred. Please try again.',
        });
        setIsLoading(false);
        setIsLoadingg(false);
      } finally {
        // setIsLoading(false); // Stop loading
        // setIsLoadingg(false);
      }
      setIsLoading(false);
      setIsLoadingg(false);

      return;
    }

    if (currentStep === 2) {
      const { socialProvider } = loginProvider;
      if (socialProvider === 'google' || socialProvider === 'facebook') {
        if (loginProvider && loginProvider.data) {
          const {
            email,
            firstName,
            lastName,
            companyName,
            country,
            name,
            accountType,
          } = latestValues;

          const updatedValues = {
            ...latestValues,
            country: selectedCountry,
            companyName: name,
          };

          setIsLoading(true);
          console.log('1111111111');
          try {
            const response = await createUserSocial(updatedValues, () => {
              // notificationWithNodes({
              //   type: 'success',
              //   title: 'Success',
              //   description: (
              //     <>
              //       <p>Welcome to dinabite!</p>
              //       <p>Please connect your tools</p>
              //     </>
              //   ),
              // });
              // });
            });

            //   const safeValues: CreateCompanyRequest = {
            //     email: latestValues.email || emaillogin || 'default@example.com', // Fallback to a default email if null, undefined, or empty
            //     name: latestValues.name || latestValues.companyName || 'Unnamed Company', // Fallback to 'Unnamed Company'
            //     country: selectedCountry || 'United States', // Default to 'United States'
            //     address: latestValues.address || '123 Default St', // Default to a generic address
            //     city: latestValues.city || 'Washington, DC', // Default to 'Washington, DC'
            //     zipCode: latestValues.zipCode || '00000', // Default to '00000'
            //     phone: latestValues.phone || '000-000-0000',
            //     website: latestValues.website || 'website',
            // };
            const safeValues: CreateCompanyRequest = {
              email: latestValues.email || emaillogin || 'default@example.com', // Fallback to a default email if null, undefined, or empty
              name: latestValues.name || latestValues.companyName || 'name', // Fallback to 'name'
              country: selectedCountry || 'United States', // Default to 'United States'
              address: latestValues.address || '123 Default St', // Default to 'United States'
              city: latestValues.city || 'Washington Dc', // Default to 'Washington Dc'
              zipCode: latestValues.postcode || '00000', // Default to '00000'
              phone: latestValues.phone || '000-000-0000', // Default to '000-000-0000'
              website: latestValues.website || 'website',
            };

            console.log('2222222');

            // Create the company
            const response2 = await createCompany(safeValues);
            console.log('333333');

            if (response.data) {
              if (!values.password) {
                if (userID) {
                  await loginSocial(tokenID, 'facebook', userID);
                } else {
                  console.log('44444');

                  await loginSocial(tokenID, 'google');
                }
              }

              // setLoginProvider(INITIAL_VALUES_PROVIDER);
              console.log('55555');

              console.log('66666');

              setCurrentStep(3);
              console.log('chnage state to step 3');
              // }
            }
          } catch (error) {
            console.error('Error during user or company creation:', error);
            // Handle error (e.g., show an error notification)
          } finally {
            // setIsLoading(false);
          }
        }
      } else {
        try {
          const token = localStorage.getItem('TOKEN');
          const userId = localStorage.getItem('USER_ID');

          setIsLoading(true);

          const safeValues: CreateCompanyRequest = {
            email: emaillogin, // Fallback if email is null or undefined
            name: latestValues.name || latestValues.companyName || 'name', // Fallback to 'name'
            country: selectedCountry || 'United States', // Default to 'United States'
            address: latestValues.address || 'United States', // Default to 'United States'
            city: latestValues.city || 'Washington Dc', // Default to 'Washington Dc'
            zipCode: latestValues.postcode || '00000', // Default to '00000'
            phone: latestValues.phone || '000-000-0000', // Default to '000-000-0000'
            website: latestValues.website || 'website',
          };

          console.log('new data is ', safeValues);
          const response2 = await createCompany(safeValues);

          if (response2) {
            console.log('sucess form details');
            setCurrentStep(3);
          } else {
            // console.error('Login failed after signup');
          }
        } finally {
          setIsLoading(false);
        }
      }
      return;
    }

    // if (currentStep === 3) {
    //   console.log("email is",emaillogin)
    // }
    if (currentStep === 4) {
      setLoginProvider(INITIAL_VALUES_PROVIDER);
      history.push('/user-panel/dashboard');
    }
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCancel = () => {
    setLoginProvider({
      tokenID: '',
      provider: '',
      userID: '',
      socialProvider: '',
      data: {
        email: '',
        name: '',
        socialId: '',
        register: false,
      },
    });
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    setCurrentStep(0);
    history.push('/');
  };

  return (
    <>
      <Container>
        <LeftPane>
          <StepHeaderContainer>
            <CreateAccountTitle
              style={{ display: currentStep === 0 ? 'none' : 'flex' }}>
              {getTitleForStep(currentStep)}
            </CreateAccountTitle>
            <StepIndicator
              style={{ display: currentStep === 0 ? 'none' : 'flex' }}>
              {[1, 2, 3, 4].map(step => (
                <StepCircle key={step} active={currentStep === step} />
              ))}
            </StepIndicator>

            {(currentStep === 1 || currentStep === 2) && (
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            )}
          </StepHeaderContainer>
          <MobileImage step={currentStep}>
            {currentStep === 0 && (
              <MobileImageContent src={welcomeImage} alt="Step 0 Image" />
            )}
            {currentStep === 1 && (
              <MobileImageContent src={welcomeImage} alt="Step 1 Image" />
            )}
            {currentStep === 2 && (
              <MobileImageContent src={welcomeImage2} alt="Step 2 Image" />
            )}
            {currentStep === 3 && (
              <MobileImageContent src={well2} alt="Step 3 Image" />
            )}
            {currentStep === 4 && (
              <MobileImageContent src={well} alt="Step 4 Image" />
            )}
          </MobileImage>
          {currentStep === 0 && (
            <Step0
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              setCurrentStep={setCurrentStep}
            />
          )}
          {currentStep === 1 && (
            <Step1
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              initialValues={formValues}
            />
          )}
          {currentStep === 2 && (
            <Step2
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              initialValues={formValues}
              loginProvider={loginProvider}
              onCountryChange={handleCountryChange} // Pass the callback
            />
          )}
          {currentStep === 3 && (
            <Step3
              onNext={() => handleNextStep(formValues)}
              onBack={handlePreviousStep}
              onCancel={handleCancel}
              firstName={formValues.firstName} // Pass the firstName to Step3
              lastName={formValues.lastName} // Pass the lastName to Step3
            />
          )}
          {currentStep === 4 && <Step4 />}
        </LeftPane>

        <RightPane>
          <ImageContainer>
            {currentStep === 0 && (
              <StyledImage src={welcomeImage} alt="Illustration" />
            )}
            {currentStep === 1 && (
              <StyledImage src={welcomeImage} alt="Illustration" />
            )}
            {currentStep === 2 && (
              <StyledImage src={welcomeImage2} alt="Illustration" />
            )}
            {currentStep === 3 && (
              <StyledImage src={toolsimage} alt="Illustration" />
            )}
            {currentStep === 4 && <StyledImage src={well} alt="Illustration" />}
            <DinabiteText>{getTextForStep(currentStep)}</DinabiteText>
          </ImageContainer>
        </RightPane>
      </Container>
      {isLoadingg && (
        <FlexboxGrid
          justify="center"
          align="middle"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          }}>
          <FlexboxGrid.Item>
            <Loader size="lg" content="Signing Up..." />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
      {showModal && (
        <Modal
          message={modalMessage}
          email={modalEmail}
          onClose={handleCloseModal}
          showModal={showModal} // Pass the showModal prop
          isLoginPage={false} // Pass false or true depending on the context
        />
      )}
    </>
  );
};

export default DinabiteLogin;
