import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from '../../lib/axios';
import {
  Container,
  Message,
  ErrorMessage,
  BackButtonDiv,
  BackButtonNext,
  DesktopBackButtonContainer,
  NextButtonContainer,
  Button,
} from './emailverification.style';
import { useAuth } from '../../context/AuthContext';
import { TOKEN } from '../../lib/globalValues';

interface PropsStorage {
  user: User;
  access_token: string;
}

const AccountActivation = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { setUser, setCompanySelected } = useAuth(); // Include setCompanySelected

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const token = queryParams.get('token');

    if (token) {
      activateAccount(token);
    } else {
      setErrorMsg('Invalid token.');
    }
  }, [search]);

  const storage = ({ user: apiUser, access_token: accessToken }: PropsStorage) => {
    setUser(apiUser);
    setCompanySelected(apiUser.companies[0]); // Set the first company
    localStorage.setItem(TOKEN, accessToken); // Store the access token
  };

  const activateAccount = async (token: string) => {
    try {
      const response = await axios.post('/users/activated', { token });
      // console.log('Response:', JSON.stringify(response));

      if (response?.data) {
        const { user, access_token } = response.data; // Destructure user and access_token

        storage(response.data); // Store user and token
        setMessage('Your account has been verified!<br />Please log in.');

        // Redirect to sign-up page
        const { email } = user; // Destructure email from user
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('access_token', access_token);

        history.push({
          pathname: '/sign-up',
          state: { activationform: true, currentStep2: 2, email },
        });
      } else {
        setErrorMsg('Activation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error activating account:', error);
      setMessage('Your token has expired; please log in and generate the link again');
    }
  };

  return (
    <Container>
      <Message dangerouslySetInnerHTML={{ __html: message }} />
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      {message && (
        <>
          <DesktopBackButtonContainer>
            <NextButtonContainer>
              <Button onClick={() => history.push('/login')}>Login</Button>
            </NextButtonContainer>
          </DesktopBackButtonContainer>

          <BackButtonDiv>
            <BackButtonNext onClick={() => history.push('/login')}>
              Go to Login
            </BackButtonNext>
          </BackButtonDiv>
        </>
      )}
    </Container>
  );
};

export default AccountActivation;
