/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useQueryClient } from 'react-query';
import { Row } from 'rsuite';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../lib/globalValues';
import ToolToogleCard from '../cards/toolToogleCard/ToolToogleCard';
import FacebookToogle from './facebookToogle/FacebookToogle';
import GoogleBusinessToggle from './googleToogle/GoogleBusinessToggle';
import InstagramToogle from './instagramToogle/InstagramToogle';
import TwitterToggle from './twitterToogle/TwitterToogle';
import TiktokToogle from './tiktokToogle/TiktokToogle';
// import UberEatsToggle from './uberEatsToogle/UberEatsToggle';
import { ColMargin, Subtitlep } from './Toogle.styles';
import { getWelcomeMessage } from '../../views/steputils/steputils';

const ToogleTools = ({
  card = false,
  signup = false,
  noText = false,
  stateTool,
  dispatchTool,
  companyId,
  invalidateQuery,
}: {
  card?: boolean;
  signup?: boolean;
  noText?: boolean;
  stateTool: any;
  dispatchTool: any;
  companyId?: number;
  invalidateQuery?: () => void;
}) => {
  // const queryClient = useQueryClient();
  // console.log("stateTool",stateTool);

  const filteredStateTool = signup
    ? Object.fromEntries(
        Object.entries(stateTool).filter(([socialName]) =>
          ['FACEBOOK', 'INSTAGRAM', 'GOOGLE', 'TIKTOK'].includes(
            socialName.toUpperCase(),
          ),
        ),
      )
    : stateTool; // Show all if signup is false

  // console.log('Filtered stateTool:', filteredStateTool);

  return (
    <Row signup={signup} style={{ marginRight: 0 }}>
      {Object.entries(filteredStateTool).map(([socialName, content]) => {
        // Determine the column size without using nested ternary expressions
        let mdSize;
        if (signup) {
          mdSize = 24;
        } else if (card) {
          mdSize = 18;
        } else {
          mdSize = 24;
        }

        const welcomeMessage = getWelcomeMessage(socialName);

        return (
          <ColMargin
            signup={signup}
            xs={24}
            md={mdSize}
            key={`${socialName}Card`}>
            <ToolToogleCard
              signup={signup}
              type={socialName}
              content={content}
              noText={!noText}>
              {
                {
                  [TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]: (
                    <FacebookToogle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]: (
                    <InstagramToogle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.GOOGLE]: (
                    <GoogleBusinessToggle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.TIKTOK]: (
                    <TiktokToogle
                      stateTool={stateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup}
                    />
                  ),
                  [TYPES_OF_SOCIAL_NETWORKS.TWITTER]: (
                    <TwitterToggle
                      stateTool={filteredStateTool}
                      dispatchTool={dispatchTool}
                      companyId={companyId}
                      invalidateQuery={invalidateQuery}
                      signup={signup} // Pass the signup prop
                    />
                  ),
                }[socialName]
              }
            </ToolToogleCard>
            {/* Display the welcome message based on the social media type */}
            {/* <div>{welcomeMessage}</div> */}
            {/* {signup && <div>{welcomeMessage}</div>} */}
            <Subtitlep>{signup && <div>{welcomeMessage}</div>}</Subtitlep>
          </ColMargin>
        );
      })}
    </Row>
  );
};

export default ToogleTools;
