import { Icon } from '@rsuite/icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useQuery } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Nav } from 'rsuite';
import {
  AccountIcon,
  AnalyticsIcon,
  HelpIcon,
  HomeIcon,
  LogoutIcon,
  OrdersIcon,
  PostsIcon,
  PromotionsIcon,
  ReviewsIcon,
  ToolsIcon,
} from '../../assets';
import { useAuth } from '../../context/AuthContext';
import useUser from '../../hooks/useUser';
import axios from '../../lib/axios';
import { useMobileStore } from '../../store';
import {
  DividerSpace,
  Menu,
  SidenavStyled,
  StyledNavLink,
} from './NavUser.styles';

const fetchTools = async () => {
  try {
    const { data } = await axios.get<Tool[]>('/tools');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const NavUser = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { handleLogout } = useUser();
  const { user } = useAuth();
  const { hideMobileMenu } = useMobileStore();

  const { data: tools } = useQuery(`TOOLS`, fetchTools, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  const hasConnectedTool = tools?.find(tool => tool.enabled);

  const isUberEatsEnabled = tools?.find(
    tool => tool.type === 'uberEats' && tool.enabled,
  );

  const logoutUser = () => {
    confirmAlert({
      message: `Do you want to logout ${user?.email}?`,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => {
            handleLogout();
            history.push('/');
          },
        },
        {
          label: 'Cancel',
          onClick: () => null,
        },
      ],
    });
  };

  return (
    <SidenavStyled>
      <Nav>
        {hasConnectedTool && (
          <Menu
            onClick={hideMobileMenu}
            componentClass={StyledNavLink}
            to={`${url}/dashboard`}
            activeClassName="current"
            icon={<Icon as={HomeIcon} />}>
            <b> Home </b>
          </Menu>
        )}
        <Menu
          onClick={hideMobileMenu}
          appearance="subtle"
          componentClass={StyledNavLink}
          to={`${url}/tools`}
          activeClassName="current"
          icon={<Icon as={ToolsIcon} />}>
          Tools
        </Menu>
        {hasConnectedTool && (
          <>
            <Menu
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/posts`}
              activeClassName="current"
              icon={<Icon as={PostsIcon} />}>
              Posts
            </Menu>
            <Menu
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/reels`}
              activeClassName="current"
              icon={<Icon as={PostsIcon} />}>
              Reels
            </Menu>
            {/* <Menu
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/tiktok`}
              activeClassName="current"
              icon={<Icon as={PostsIcon} />}>
              Additional TikTok Features
            </Menu> */}
            <Menu
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/promotions`}
              activeClassName="current"
              icon={<Icon as={PromotionsIcon} />}>
              Promotions
            </Menu>
            <Menu
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/reviews`}
              activeClassName="current"
              icon={<Icon as={ReviewsIcon} />}>
              Reviews
            </Menu>
            <Menu
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/analytics`}
              activeClassName="current"
              icon={<Icon as={AnalyticsIcon} />}>
              Analytics
            </Menu>
          </>
        )}
        <DividerSpace />
        {isUberEatsEnabled && (
          <>
            <Menu
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/delivery`}
              activeClassName="current"
              icon={<Icon as={OrdersIcon} />}>
              Delivery
            </Menu>
            <DividerSpace />
          </>
        )}
        <Menu
          onClick={hideMobileMenu}
          componentClass={StyledNavLink}
          to={`${url}/account`}
          activeClassName="current"
          icon={<Icon as={AccountIcon} />}>
          Account
        </Menu>
        <Menu
          onClick={hideMobileMenu}
          componentClass={StyledNavLink}
          to={`${url}/help`}
          activeClassName="current"
          icon={<Icon as={HelpIcon} />}>
          Feedback/Help
        </Menu>
        <DividerSpace />
        <Menu onClick={logoutUser} icon={<Icon as={LogoutIcon} />}>
          Logout
        </Menu>
      </Nav>
    </SidenavStyled>
  );
};

export default NavUser;
