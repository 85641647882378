import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Loader, FlexboxGrid } from 'rsuite';
import {
  ContentContainer,
  Title,
  Titlembile,
  StyledErrorMessage,
  Subtitlemobilebottom,
  BackButtondiv,
  BackButtonnext,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  DesktopBackButton,
  Button,
  Subtitlemobile,
  Subtitle,
  StyledInput2,
  Spinner,
  CheckboxContainer,
  Textt,
  AsteriskNote,
} from './step1.style';
import { firstPageSocialSchema } from '../../components/forms/formSignUp/validation';
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput';

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
  agreeToTerms: boolean; // Added field for checkbox
}

interface Step1Props {
  onNext: (values: any) => void;
  onBack: () => void;
  onCancel: () => void;
  initialValues: FormValues;
}

const Step1: React.FC<Step1Props> = ({
  onNext,
  onBack,
  onCancel,
  initialValues,
}: Step1Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Formik
      initialValues={{ ...initialValues, agreeToTerms: false }}
      validationSchema={firstPageSocialSchema}
      enableReinitialize
      validateOnMount
      onSubmit={(values, { setSubmitting, validateForm, resetForm }) => {
        setIsLoading(true); // Start loading

        validateForm().then(errors => {
          if (Object.keys(errors).length === 0) {
            setTimeout(() => {
              console.log('values ', values);
              onNext(values);
              resetForm(); // Reset the form after submission
              // console.log("loading false")
              setIsLoading(false);
            }, 4000); // 4 seconds delay
          } else {
            console.log('form not correctly SUbmited');
            setIsLoading(false);
          }
        });
      }}>
      {({
        isSubmitting,
        touched,
        errors,
        handleChange,
        handleBlur,
        values,
        isValid,
        dirty,
      }) => (
        <Form>
          <ContentContainer>
            <Title>Welcome to the Future of Marketing</Title>
            <Subtitle>
              Hey there! Ready to embark on a marketing adventure with Dinabite?
              Let’s kick things off with your name, email and a secure password.
            </Subtitle>
            <Titlembile>Welcome to the Future of Marketing</Titlembile>
            <Subtitlemobile>
              Ready to embark on a marketing adventure with Dinabite.ai? Let’s
              kick things off with your name, email and a secure password.
            </Subtitlemobile>
            <Field
              name="firstName"
              type="text"
              placeholder="First Name*"
              as={StyledInput2}
            />
            <ErrorMessage name="firstName" component={StyledErrorMessage} />
            <Field
              name="lastName"
              type="text"
              placeholder="Last Name*"
              as={StyledInput2}
            />
            <ErrorMessage name="lastName" component={StyledErrorMessage} />
            <Field
              name="email"
              type="email"
              placeholder="Email*"
              as={StyledInput2}
            />
            <ErrorMessage name="email" component={StyledErrorMessage} />
            <PasswordInput
              signUp
              placeholder="Password*"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledErrorMessage>
              {touched.password && errors.password
                ? errors.password
                : undefined}
            </StyledErrorMessage>
            {/* <PasswordInput
              signUp
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <StyledErrorMessage>
              {touched.confirmPassword && errors.confirmPassword
                ? errors.confirmPassword
                : undefined}
            </StyledErrorMessage> */}
            <CheckboxContainer>
              <Field
                type="checkbox"
                name="agreeToTerms"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.agreeToTerms}
              />
              <Textt>I agree to the Terms, Conditions and Privacy Policy</Textt>
            </CheckboxContainer>
            {touched.agreeToTerms && errors.agreeToTerms && (
              <StyledErrorMessage>{errors.agreeToTerms}</StyledErrorMessage>
            )}
            <AsteriskNote>* Required fields</AsteriskNote>

            <Subtitlemobilebottom>
              Exciting times ahead! With Dinabite, you’ll be posting on
              Facebook, Instagram, Twitter/X, and Google My Business like a pro.
              Let’s get started!
            </Subtitlemobilebottom>

            <DesktopBackButtonContainer>
              <NextButtonContainer>
                <Button
                  type="submit"
                  disabled={
                    isLoading ||
                    isSubmitting ||
                    !values.agreeToTerms ||
                    !isValid ||
                    !dirty
                  }>
                   Next Step
                </Button>
              </NextButtonContainer>
              <BackCancelContainer>
                <DesktopBackButton onClick={onBack}>Back</DesktopBackButton>
              </BackCancelContainer>
            </DesktopBackButtonContainer>
            <BackButtondiv>
              <BackButtonnext
                type="submit"
                disabled={
                  isLoading ||
                  isSubmitting ||
                  !values.agreeToTerms ||
                  !isValid ||
                  !dirty
                }>
                Next Step
              </BackButtonnext>
            </BackButtondiv>
          </ContentContainer>
          {isLoading && (
            <FlexboxGrid
              justify="center"
              align="middle"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
              }}>
              <FlexboxGrid.Item>
                <Loader size="lg" content="Signing Up..." />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default Step1;
