/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { Col, Content, FlexboxGrid, Loader, Row } from 'rsuite';
import StatisticCard from '../../components/cards/statisticCard/StatisticCard';
import Messages from '../../components/messages/Messages';
import SelectButtons from '../../components/selectButtons/SelectButtons';
import useReviewsStatisticsReviews from '../../hooks/useReviewsStatisticsReviews';
import axios from '../../lib/axios';
import { formatNumber, getCurrencySymbol } from '../../lib/helpers';
import { selectImgStatus } from '../../lib/toogleImgStatus';
import {
  FlexboxGridMb,
  Title,
  TitleAside,
  TitleContent,
  ToolImage,
  ToolsContainer,
  StyledFlexboxGridItem,
} from './Dashboard.styles';

const CONTENT_SELECT_BUTTONS = [
  {
    value: 'yesterday',
    content: 'Yesterday',
  },
  {
    value: 'last7Days',
    content: 'Last 7 Days',
  },
];

const fetchTools = async () => {
  try {
    const { data } = await axios.get<Tool[]>('/tools');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const Dashboard = () => {
  const [filter, setFilter] = useState(CONTENT_SELECT_BUTTONS[0].value);

  const history = useHistory();

  const fetchDashboardStatistics = async () => {
    try {
      const { data } = await axios.get<DashboardStatistics>(
        `/tools/statistics/dashboard?filter=${filter}`,
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  };

  const {
    isLoading: isLoadingStatistics,
    data: statistics,
    isError,
  } = useQuery(`dashboardStatistics-${filter}`, fetchDashboardStatistics, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  const {
    isFetched,
    isLoading: isLoadingTools,
    data: tools,
    isError: isErrorTools,
  } = useQuery(`TOOLS`, fetchTools, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const hasConnectedTool = tools?.find(tool => tool.enabled);
    if (!hasConnectedTool && isFetched) {
      history.push('tools');
    }
  }, [isFetched, tools]);

  const {
    isLoading: isLoadingStatisticsReviews,
    data: statisticsReviews,
    isError: isErrorReviews,
  } = useReviewsStatisticsReviews(filter);

  if (isLoadingTools) {
    return <Loader content="Loading..." />;
  }

  const desiredOrder = ['facebook', 'instagram', 'google', 'tiktok', 'twitter'];

  const sortedTools =
    tools &&
    tools.sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.type);
      const indexB = desiredOrder.indexOf(b.type);
      return indexA - indexB;
    });

  return (
    <Content>
      <FlexboxGrid justify="space-around">
        <FlexboxGrid.Item componentClass={Col} xs={24} md={15}>
          <FlexboxGridMb align="middle" justify="end">
            <FlexboxGrid.Item componentClass={Col} xs={24} mdHidden lgHidden>
              <Title>Dashboard</Title>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} xs={24} md={12} />
            <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
              <FlexboxGrid justify="space-around">
                <SelectButtons
                  content={CONTENT_SELECT_BUTTONS}
                  defaultValue={filter}
                  setPage={setFilter}
                />
              </FlexboxGrid>
            </FlexboxGrid.Item>
          </FlexboxGridMb>
          <Row>
            <TitleContent>Active Tools</TitleContent>
            {isLoadingTools && <Loader content="Loading..." />}
            {!isLoadingTools && !isErrorTools && tools && (
              <ToolsContainer>
                {!tools.length && (
                  <p>
                    You do not have any connected tools. You can connect them on
                    the
                    <Link to="tools"> Tools page</Link>
                  </p>
                )}
                {sortedTools?.map((tool: Tool) => (
                  <ToolImage
                    key={(tool?.pageId || tool?.userId) + tool.type}
                    src={selectImgStatus(tool.type, true).image}
                    alt={tool.type}
                  />
                ))}
              </ToolsContainer>
            )}
          </Row>

          {tools && tools.length > 0 && (
            <>
              <Row>
                <TitleContent>Activity</TitleContent>
                {isLoadingStatistics && <Loader content="Loading..." />}

                {!isLoadingStatistics && !isError && statistics && (
                  <>
                    <Row>
                      <StatisticCard
                        simple
                        label="New posts"
                        value={statistics?.posts || 0}
                        withoutMargin
                        dashboardCard
                      />
                      <StatisticCard
                        simple
                        withoutMargin
                        label="Ad spend"
                        value={`${getCurrencySymbol(
                          statistics?.adSpend?.currency,
                        )}${formatNumber(statistics?.adSpend?.amount || 0, 2)}`}
                        dashboardCard
                      />
                    </Row>

                    <TitleContent>Reach and Engagement</TitleContent>
                    <Row>
                      <StatisticCard
                        label="Views"
                        statistics={statistics.views}
                        withoutMargin
                        dashboardCard
                      />
                      <StatisticCard
                        withoutMargin
                        label="Engagement"
                        statistics={statistics.engagement}
                        dashboardCard
                      />
                    </Row>
                  </>
                )}
              </Row>

              <TitleContent>Reviews</TitleContent>

              <Row>
                {isLoadingStatisticsReviews && <Loader content="Loading..." />}
                {!isLoadingStatisticsReviews &&
                  !isErrorReviews &&
                  statisticsReviews && (
                    <Row>
                      <StatisticCard
                        simple
                        label="Total reviews"
                        value={statisticsReviews?.total?.totalReviews || 0}
                        withoutMargin
                        dashboardCard
                      />
                      <StatisticCard
                        simple
                        label="Average score"
                        value={
                          !statisticsReviews?.total?.averageScore
                            ? 'N/A'
                            : statisticsReviews.total.averageScore
                        }
                        decimalNumbers={1}
                        withoutMargin
                        dashboardCard
                      />
                    </Row>
                  )}
              </Row>
            </>
          )}
        </FlexboxGrid.Item>
        <StyledFlexboxGridItem
          componentClass={Col}
          xs={24}
          md={7}
          justify="end">
          <FlexboxGrid align="bottom">
            <FlexboxGrid.Item colspan={18}>
              <TitleAside>Latest messages</TitleAside>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Messages />
        </StyledFlexboxGridItem>
      </FlexboxGrid>
    </Content>
  );
};

export default Dashboard;
