import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../../lib/axios';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string');

const TikTok = () => {
  const getToken = async () => {
    try {
      const { state, code, error } = queryString.parse(params.search);

      if (error === 'access_denied') {
        localStorage.setItem(
          'tiktokError',
          JSON.stringify({ access_denied: true }),
        );
        window.close();
        return error;
      }

      if (state && code) {
        const { data } = await axios.get('tools/tiktokCallback', {
          params: { state, code },
        });

        const tiktokData = {
          accessToken: data.access_token,
          userId: data?.userId,
          id: data?.pageId,
          name: data?.pageName,
        };
        localStorage.removeItem('tiktokError');
        localStorage.setItem('tiktok', JSON.stringify(tiktokData));
        window.close();
        return data;
      }

      // If no conditions are met
      return null;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error occurred:', err);

      // You can add any additional error handling here, if needed.
      window.close();
      return err; // Return the caught error.
    }
  };

  useEffect(() => {
    getToken();
  }, []);
  const params = useLocation();

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
};

export default TikTok;
