import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendWelcomeEmail } from '../../services/welcomEmail';
import { findUser } from '../../services/findUser';
import {
  Title,
  Subtitle,
  Button,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  BackButtondiv,
  BackButtonnext,
  ContentContainer,
  LoaderContainer,
  Spinner,
} from './step4.styes';
import { useAuth } from '../../context/AuthContext';

const Step4 = () => {
  const history = useHistory();
  const { loginProvider } = useAuth();
  const { data } = loginProvider || {};
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true); // Set loading state to true

    // Check for user in localStorage first
    const user = localStorage.getItem('user');
    // console.log("localstorage user ",user)
    if (user) {
      const parsedUser = JSON.parse(user);
      // console.log('User found in localStorage:', parsedUser);

      const { id, email } = parsedUser;
      if (id && email) {
        // console.log('User ID for email sending:', id);
        // console.log('Email sending:', email);

        const emailSent = await sendWelcomeEmail(id, email);
        if (emailSent) {
          console.log('Welcome email sent successfully:', emailSent);
        } else {
          console.log('Failed to send welcome email.');
        }
        return; // Exit if we sent the email from localStorage
      }
    }

    // If no user in localStorage, check login provider data
    if (data) {
      console.log('Login Provider Data:', loginProvider.data);
      const email2 = data.email;

      if (email2) {
        console.log('Email is', email2);

        // Fetch user details using the provided email
        const response = await findUser(email2);

        // Check if the response is successful
        if (response && response.data && Array.isArray(response.data)) {
          const userData = response.data[0]; // Assuming the response is an array
          const { id, email } = userData;

          if (id && email) {
            console.log('User ID:', id);
            console.log('User Email:', email);

            const emailSent = await sendWelcomeEmail(id, email);
            if (emailSent) {
              console.log('Welcome email sent successfully:', emailSent);
            } else {
              console.log('Failed to send welcome email.');
            }
          } else {
            console.log('User data is incomplete in response.');
          }
        } else {
          console.log('Failed to fetch user data.');
        }
      } else {
        console.log('Email is not available in login provider data.');
      }
    } else {
      console.log('No login provider data available.');
    }

    setLoading(false); // Ensure loading state is reset
  };

  const onSubmit = () => {
    fetchData(); // Call fetchData on button click
    // alert('Welcome to the dashboard');
    // Uncomment to navigate after fetching data
    history.push('/user-panel/dashboard');
  };

  return (
    <ContentContainer>
      <Title>Welcome aboard!</Title>
      <Subtitle>
        You are now ready to explore Dinabite and take your social media game to
        the next level. Let&apos;s start this exciting journey together.
      </Subtitle>

      <DesktopBackButtonContainer>
        <NextButtonContainer>
          <Button onClick={onSubmit} disabled={loading}>
            {loading ? (
              <LoaderContainer>
                <Spinner />
              </LoaderContainer>
            ) : (
              'Go to Dashboard'
            )}
          </Button>
        </NextButtonContainer>
        <BackCancelContainer>
          {/* <DesktopBackButton onClick={onCancel}>Cancel</DesktopBackButton> */}
        </BackCancelContainer>
      </DesktopBackButtonContainer>
      <BackButtondiv>
        <BackButtonnext onClick={onSubmit}>Go to Dashboard</BackButtonnext>
      </BackButtondiv>
    </ContentContainer>
  );
};

export default Step4;
