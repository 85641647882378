/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import axios from '../lib/axios';
import { capitalize } from '../lib/helpers';
import { notification, notificationError } from '../lib/notification';

interface TikTokVideoData {
  title: string;
  mediaId: any;
  socialNetworks: string[];
  isPrivacyLevel: string;
  allowComments: boolean;
  allowDuet: boolean;
  allowStitch: boolean;
  brandOrganicToggle: boolean;
  brandContentToggle: boolean;
  disclosePostContent: boolean;
  isAiGenerated: boolean;
}

interface PostPhotoProps {
  title: string;
  mediaId: any;
  socialNetworks: string[];
  isPrivacyLevel: string;
  description?: string;
  allowComments: boolean;
  autoAddMusic: boolean;
  brandContentToggle: boolean;
  brandOrganicToggle: boolean;
}

export const postVideoToTikTok = async ({
  title,
  mediaId,
  socialNetworks,
  isPrivacyLevel,
  allowComments,
  allowDuet,
  allowStitch,
  brandOrganicToggle,
  brandContentToggle,
  disclosePostContent,
  isAiGenerated,
}: TikTokVideoData): Promise<any> => {
  try {
    const response = await axios.post('/tiktok', {
      title,
      mediaId,
      socialNetworks,
      privacyLevel: isPrivacyLevel,
      allowComments,
      allowDuet,
      allowStitch,
      brandOrganicToggle,
      brandContentToggle,
      disclosePostContent,
      isAiGenerated,
    });

    const { data } = response;

    // if (data) {
    //   Object.entries(data).forEach(([socialName, content]: [string, any]) => {
    //     if (content === true) {
    //       notification({
    //         type: 'success',
    //         title: capitalize(socialName),
    //         description: 'Post was successfully published',
    //       });
    //     } else {
    //       notificationError({
    //         title: capitalize(socialName),
    //         description: content || 'The post could not be made.',
    //       });
    //     }
    //   });
    // }

    return data;
  } catch (error: any) {
    if (error.response?.status === 403) {
      notificationError({
        title: 'Error',
        description: 'API Key not correct. Please provide a valid API key.',
      });
    } else {
      notificationError({
        title: 'Error',
        description: 'Something went wrong',
      });
    }

    return error;
  }
};

export const postPhotosToTiktok = async ({
  title,
  mediaId,
  socialNetworks,
  isPrivacyLevel,
  description,
  allowComments,
  autoAddMusic,
  brandContentToggle,
  brandOrganicToggle,
}: PostPhotoProps): Promise<any> => {
  try {
    const response = await axios.post('/tiktok', {
      title,
      mediaId,
      socialNetworks,
      privacyLevel: isPrivacyLevel,
      description,
      allowComments,
      autoAddMusic,
      brandContentToggle,
      brandOrganicToggle,
    });

    const { data } = response;

    // if (data) {
    //   notification({
    //     type: 'success',
    //     title: 'Success',
    //     description: 'Photo was successfully posted to TikTok!',
    //   });
    // }

    return data;
  } catch (error: any) {
    if (error.response?.status === 403) {
      notificationError({
        title: 'Error',
        description: 'Invalid API Key.',
      });
    } else {
      notificationError({
        title: 'Error',
        description: 'Something went wrong while posting.',
      });
    }

    return error;
  }
};
