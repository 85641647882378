import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

interface SendWelcomeEmailProps {
  userId: number;
  email: string;
}

export const sendWelcomeEmail = async (userId: number, email: string) => {
  console.log('Received email for user ID:', userId, 'and email:', email);

  try {
    const response = await axios.post('/mail/welcome', {
      userId,
      email,
    });

    // notification({
    //   type: 'success',
    //   title: 'Success',
    //   description: 'Welcome email has been sent successfully. Please check your inbox.',
    // });

    return response;
  } catch (error) {
    console.error('Error sending welcome email:', error);

    // notificationError({
    //   title: 'Error',
    //   description: 'Failed to send the welcome email.',
    // });

    return error;
  }
};
