import axios from '../lib/axios';

export const findUser = async (email: string) => {
  try {
    const response = await axios.post('/users/find', { email });
    return response;
  } catch (error) {
    return error;
  }
};
export const checkCompanyExists = async (email: string): Promise<boolean> => {
  try {
    const response = await axios.post('/companies/check-company', { email });
    console.log("Response from checkCompanyExists:", response.data); // Log the entire response
    return response.data; 
  } catch (error) {
    console.error("Error checking company existence:", error);
    return false; // Return false if there's an error
  }
};
