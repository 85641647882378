import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
// import { useHistory } from 'react-router-dom';
import { Loader, FlexboxGrid } from 'rsuite';
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import * as Yup from 'yup';

import {
  Button,
  BackButton,
  BackButtondiv,
  BackButtonnext,
  ContentContainer,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  DesktopBackButton,
  // DesktopCancelButton,
  StyledErrorMessage,
  // Titlembile,
  Title,
  Subtitle,
  // Subtitlemobile,
  Subtitlemobilebottom,
  Titlembile,
  StyledInput,
  Subtitlemobile,
  SearchContainer,
  StyledErrorMessage2,
  PhoneInputContainer,
  AsteriskNote,
} from './step2.style';
import { useAuth } from '../../context/AuthContext';
import useUser from '../../hooks/useUser';
import SelectCountry from '../../components/forms/formSignUp/selectCountry/SelectCountry';
import { secondPageSchema } from '../../components/forms/formSignUp/validation';

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  postcode: Yup.string().required('Postcode is required'),
});

interface FormValues {
  email: string;
  password: string;
  confirmPassword?: string;
  companyName?: string;
  country?: string;
  name?: string;
  accountType?: string;
  address?: string;
  street?: string; // New field
  postcode?: string; // New field
  city?: string; // New field
  phone?: string; // New field
  wensite?: string; // New field
}
interface CountryOption {
  value: string;
  label: string;
  phoneCode: string;
}

interface Step2Props {
  onNext: (values: any) => Promise<void>;
  onBack: () => void;
  onCancel: () => void;
  initialValues: FormValues;
  loginProvider: any;
  onCountryChange: (country: string) => void;
}
const Step2: React.FC<Step2Props> = ({
  onNext,
  onBack,
  onCancel,
  initialValues,
  loginProvider,
  onCountryChange,
}: Step2Props) => {
  // const { loginProvider, setLoginProvider } = useAuth();
  // const { loginSocial } = useUser();
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  // const [country2, setCountryy] = useState("");

  // const [field,setField]=useState("");
  const [phoneCode, setPhoneCode] = useState(''); // State for phone code
  const { setUser, setLoginProvider, setCompanySelected } = useAuth();
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [curretFormValues, setCurrentFormValues] = useState<FormValues>();
  const isFormInitialized = useRef(false); // To prevent multiple reinitializations
  const isMounted = useRef(true); // Track component mount status
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log("User timezone - ", tz);
  const inputRef = useRef<google.maps.places.SearchBox | null>(null);
  const [resendemail, setresentemail] = useState('');
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: ['places'],
  });
  // useEffect(() => {
  //   // Check if user data exists in local storage
  //   const user = localStorage.getItem('user');
  //   if (user) {
  //     const parsedUser = JSON.parse(user);
  //     console.log('User found in localStorage:', parsedUser);

  //     // You can set state or do whatever you need with parsedUser
  //     const { email } = parsedUser; // Extract the email if needed
  //     console.log("email of user in localstorage is ",email)
  //     console.log("  user in localstorage is ",user)
  //   } else {
  //     console.log('No user found in localStorage.');
  //   }
  // }, []);
  useEffect(() => {
    // Check if user data exists in local storage
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      // console.log('User found in localStorage:', parsedUser);

      // You can set state or do whatever you need with parsedUser
      const { email } = parsedUser; // Extract the email if needed
      // console.log('email of user in localstorage is ', email);
      // console.log('  user in localstorage is ', user);
      if (parsedUser.companies && parsedUser.companies.length > 0) {
        const companyId = parsedUser.companies[0].id; // Accessing the first company's ID
        console.log('Company ID:', companyId);
      } else {
        console.log('No companies found for this user.');
      }
    } else {
      console.log('No user found in localStorage.');
    }
  }, []);

  useEffect(() => {
    console.log('form updated', curretFormValues?.country);
  }, [curretFormValues]);
  useEffect(() => {
    if (loginProvider && loginProvider.data && !isFormInitialized.current) {
      console.log('Login Provider Data:', loginProvider.data);

      const { name, email } = loginProvider.data;
      const [firstName = '', lastName = ''] = name ? name.split(' ') : [];
      setFormValues(prevValues => ({
        ...prevValues,
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        companyName: '',
        country: undefined,
        password: '',
      }));
      isFormInitialized.current = true;
    }

    return () => {
      isFormInitialized.current = false;
    };
  }, [loginProvider]);

  const handleCountryChange = useCallback((country: string, code: string) => {
    // console.log('Country changed to: ', country, ' with code: ', code);
    setPhoneCode(code); // Update the phone code state with the new code
    // setFieldValue('phoneCode', code); // Update Formik field
    // console.log('phone cod eis ', code);
    // Your logic here
  }, []);

  useEffect(() => {
    // Check if user data exists in local storage
    const userData = localStorage.getItem('user');
    if (userData) {
      const parsedUser = JSON.parse(userData);
      // console.log('User found in localStorage:', parsedUser);
      // Example: Logging the email
      // console.log('Email of user in local storage is:', parsedUser.email);
    } else {
      console.log('No user found in localStorage.');
    }
  }, []);

  const handlePlacesChanged = (
    setFieldValue: any,
    setFieldTouched: any,
    setFieldError: any,
  ) => {
    if (inputRef.current) {
      const places = inputRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        const address = place.formatted_address || '';
        const street =
          place.address_components?.find(comp => comp.types.includes('route'))
            ?.long_name || '';
        const city =
          place.address_components?.find(comp =>
            comp.types.includes('locality'),
          )?.long_name || '';
        const postcode =
          place.address_components?.find(comp =>
            comp.types.includes('postal_code'),
          )?.long_name || '';
        const country =
          place.address_components?.find(comp => comp.types.includes('country'))
            ?.long_name || '';

        console.log('address', address);
        console.log('street', street);
        console.log('city', city);
        console.log('postcode', postcode);
        console.log('country', country);

        // Ensure that place.address_components exists and has a length greater than 0

        setFieldValue('address', address);
        setFieldValue('street', street);
        setFieldValue('city', city);
        setFieldValue('postcode', postcode);
        setFieldValue('country', country); // Set the country field as well
        // setFieldValue('country', country); // Update Formik state
        // setFieldTouched('country', true); // Mark the field as touched
        // handleCountryChange(country); // Notify parent about country change

        // Validation
        // if (!street) {
        //   setFieldError('street', 'Street is required');
        // }
        // if (!city) {
        //   setFieldError('city', 'City is required');
        // }
        // if (!postcode) {
        //   setFieldError('postcode', 'Postcode is required');
        // }
        //   if (!country) {
        //     setFieldError('country', 'Country is required'); // Optional validation for country
        // }
      }
    }
  };

  const handleSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
    inputRef.current = ref;
  };
  return (
    <Formik
      initialValues={
        loginProvider && loginProvider.data ? formValues : initialValues
      }
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (
        values,
        { setTouched, resetForm, setFieldTouched, setFieldError },
      ) => {
        console.log('values is', values);
        setTouched({
          email: true,
          companyName: true,
          country: true,
          name: true,
          phone: true,
          address: true,
        });
        setFieldTouched('address', true);
        setFieldTouched('street', true);
        setFieldTouched('city', true);
        setFieldTouched('postcode', true);
        setIsLoading(true);
        try {
          // await onNext(values);
          await onNext(values);
          setIsLoading(true);

          resetForm();
        } catch (error) {
          console.error('Error during form submission:', error);
          setIsLoading(true);
        } finally {
          setIsLoading(false);
        }
      }}>
      {({
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
        setFieldError,
        values,
        isValid,
        dirty,
      }) => (
        <>
          <Form>
            <ContentContainer>
              <Title>Welcome to the Future of Marketing</Title>
              <Subtitle>
                Please enter some extra information about your business so you
                can have a better service and a more effective AI support .
              </Subtitle>
              <Titlembile>Let’s get to know each other.</Titlembile>
              <Subtitlemobilebottom>
                Please enter some extra information about your business so you
                can have a better service and a more effective AI support.
              </Subtitlemobilebottom>
              <Field
                name="companyName"
                type="text"
                placeholder="Business name*"
                as={StyledInput}
              />
              <ErrorMessage name="companyName" component={StyledErrorMessage} />

              <SearchContainer>
                {isLoaded && (
                  // <StandaloneSearchBox onLoad={handleSearchBoxLoad} onPlacesChanged={handlePlacesChanged}>
                  // <StandaloneSearchBox onLoad={handleSearchBoxLoad} onPlacesChanged={() => handlePlacesChanged(setFieldValue)}>
                  <StandaloneSearchBox
                    onLoad={handleSearchBoxLoad}
                    onPlacesChanged={() =>
                      handlePlacesChanged(
                        setFieldValue,
                        setFieldTouched,
                        setFieldError,
                      )
                    }>
                    <Field
                      name="address"
                      as={StyledInput}
                      placeholder="Address*"
                      style={{ width: '100%' }}
                      autoComplete="off" // Disable autofill
                    />
                  </StandaloneSearchBox>
                )}
                <ErrorMessage name="address" component={StyledErrorMessage2} />
              </SearchContainer>

              <Field
                name="city"
                type="text"
                placeholder="City*"
                as={StyledInput}
              />
              <ErrorMessage name="city" component={StyledErrorMessage} />

              <Field
                name="postcode"
                type="text"
                placeholder="Postcode*"
                as={StyledInput}
              />
              <ErrorMessage name="postcode" component={StyledErrorMessage} />

              <Field
                name="country"
                component={SelectCountry}
                menuPlacement="top"
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                errors={errors}
                selectedCountry={values.country} // Ensure this is not undefined
                onCountryChange={handleCountryChange}
              />

              <StyledErrorMessage>
                {touched.country && errors.country && errors.country}
              </StyledErrorMessage>

              <PhoneInputContainer>
                <Field
                  name="phoneCode"
                  type="tel"
                  placeholder="Code"
                  as={StyledInput}
                  value={phoneCode} // Use the local state for value
                  style={{ width: '30%', marginRight: '5px' }} // Adjust width as necessary
                  disabled
                />
                <Field
                  name="phone"
                  type="number"
                  placeholder="Phone"
                  as={StyledInput}
                  style={{ width: '70%', flexGrow: 1 }} // Corrected style object
                />
              </PhoneInputContainer>
              <ErrorMessage name="phone" component={StyledErrorMessage} />

              <Field
                name="website"
                type="text"
                placeholder="Website"
                as={StyledInput}
              />
              <ErrorMessage name="website" component={StyledErrorMessage} />
              <AsteriskNote>* Required fields</AsteriskNote>

              <DesktopBackButtonContainer>
                <NextButtonContainer>
                  <Button
                    type="submit"
                    disabled={isLoading || !isValid || !dirty}>
                    {isLoading ? 'Signing Up...' : 'Next Step'}
                  </Button>
                </NextButtonContainer>
                <BackCancelContainer>
                  <DesktopBackButton onClick={onCancel}>
                    Cancel
                  </DesktopBackButton>
                </BackCancelContainer>
              </DesktopBackButtonContainer>
            </ContentContainer>

            <BackButtondiv>
              <BackButton onClick={onBack}>Back</BackButton>
              <BackButtonnext
                type="submit"
                disabled={isLoading || !isValid || !dirty}>
                {isLoading ? 'Signing Up...' : 'Next Step'}
              </BackButtonnext>
            </BackButtondiv>
          </Form>

          {isLoading && (
            <FlexboxGrid
              justify="center"
              align="middle"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
              }}>
              <FlexboxGrid.Item>
                <Loader size="lg" content="Signing Up..." />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          )}
        </>
      )}
    </Formik>
  );
};

export default Step2;
