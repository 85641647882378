import { createGlobalStyle } from 'styled-components';
import ComfortaaRegular from '../fonts/Comfortaa-Regular.ttf';
import ComfortaaLight from '../fonts/Comfortaa-Light.ttf';
import ComfortaaBold from '../fonts/Comfortaa-Bold.ttf';
import PoppinsRegular from '../fonts/Poppins-Regular.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Comformaa';
      src: url(${ComfortaaRegular}) format('truetype');
  }
  @font-face {
      font-family: 'Comformaa';
      src: url(${ComfortaaBold}) format('truetype');
      font-weight: bold;
  }
  @font-face {
      font-family: 'Comformaa';
      src: url(${ComfortaaLight}) format('truetype');
      font-style: italic, oblique;
  }
  @font-face {
      font-family: 'Poppins';
      src: url(${PoppinsRegular}) format('truetype');
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Comformaa', Helvetica, Sans-Serif;
  }

  .rs-modal-content {
    padding: 0px;

    @media (max-width: 968px) {
      max-width: 900px;
      margin: auto;
    }

    @media (max-width: 768px) {
      max-width: 600px;
    }

    @media (max-width: 430px) {
      max-width: 400px;
    }

    @media (max-width: 375px) {
      max-width: 360px;
    }
  }
  .rs-modal-header {
    padding: 20px 20px 0px;
    text-align: center;
  }

  .rs-picker-menu.rs-picker-select-menu {
    transform: translate(0px, -98px);
  }

  .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover, .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background: #f5e4e4;

  }

  .rs-picker-select-menu-item.rs-picker-select-menu-item-active,
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
    color: red;
    background: #f5e4e4;
  }

  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: red;
    background-color: red;
  }

  .rs-checkbox-wrapper::before {
    border: 1px solid red;
  }

  .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: red;
  }

  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    border-color: red;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: red;
  }

  @media (max-width: 820px) {
    .custom-modal .rs-modal-dialog {
    width: 92%;
    margin: 0 auto;
  }
}

  @media (max-width: 768px) {
    .custom-modal .rs-modal-dialog {
    width: 95%;
  }
}

@media (max-width: 576px) {
  .custom-modal .rs-modal-dialog {
    width: 65%;
    margin: 0 19px;
  }
}

@media (max-width: 476px) {
  .custom-modal .rs-modal-dialog {
    width: 45%;
    margin: 0 19px;
  }
}

@media (max-width: 376px) {
  .custom-modal .rs-modal-dialog {
    width: 42%;
    margin: 0 19px;
  }
    
}
`;

export default GlobalStyle;
