/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  ButtonAdd,
  ButtonContainer,
  Container,
  ParagraphColor,
  TextContainer,
} from '../Toogle.styles';
import axios from '../../../lib/axios';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { disableTool } from '../../../services/disableTool';
import { notificationError } from '../../../lib/notification';
import { handleTiktokToolChange } from '../../../services/tiktokTools';
import TiktokWindow from '../../socialWindows/TiktokWindow';

interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?: boolean;
}

const TiktokToogle = ({
  companyId,
  stateTool,
  dispatchTool,
  invalidateQuery,
  signup = false,
}: Props) => {
  const theme = useTheme();
  const [hasFocus, setHasFocus] = useState(true);
  const [isTiktok, setIsTiktok] = useState<boolean>(false);
  const [authUrl, setAuthUrl] = useState<string>('');
  const activated =
    stateTool[TYPES_OF_SOCIAL_NETWORKS.TIKTOK]?.enabled === true;

  const tiktokChecked = async () => {
    try {
      const { success, auth_url } = await startOAuth2Flow();
      if (success) {
        setAuthUrl(auth_url);
        setIsTiktok(true);
        handleTiktokToolChange({
          setIsTiktok,
          dispatchTool,
          companyId,
          invalidateQuery,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      //  console.error(`OAuth 2.0 flow failed: ${error.message}`);
    }
  };

  const startOAuth2Flow = async () => {
    const response = await axios.get('/tools/startTiktokAuth');

    return response.data;
  };

  useEffect(() => {
    const handleFocus = () => setHasFocus(prev => !prev);
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus);
  }, []);

  useEffect(() => {
    const rawError = localStorage.getItem('tiktokError');
    const storedError = rawError ? JSON.parse(rawError) : null;

    if (storedError && storedError.access_denied) {
      notificationError({
        title: 'Error',
        description: 'The TikTok account could not be added.',
      });
      localStorage.removeItem('tiktokError');
    }
  }, [hasFocus]);

  return (
    <>
      {isTiktok && <TiktokWindow auth_url={authUrl} />}

      {activated ? (
        <Container
          style={{
            justifyContent: signup ? 'end' : 'space-between',
          }}>
          {signup && (
            <ButtonAdd
              onClick={() => {
                if (companyId) {
                  disableTool(
                    stateTool[TYPES_OF_SOCIAL_NETWORKS.TIKTOK].id,
                  ).then(() => {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TIKTOK });
                    invalidateQuery?.();
                  });
                } else {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TIKTOK });
                }
              }}
              disabled={signup}
              style={{
                width: signup ? '120px' : 'auto',
                color: signup ? '#64E000' : 'inherit',
                fontFamily: signup ? "'Inter', sans-serif" : 'inherit',
                cursor: signup ? 'not-allowed' : 'pointer',
              }}>
              Connected
            </ButtonAdd>
          )}
          {!signup && (
            <TextContainer>
              <ParagraphColor status={1}>Connected</ParagraphColor>
            </TextContainer>
          )}
          {!signup && (
            <ButtonAdd
              bgColor={signup ? 'black' : theme.colors.red}
              onClick={() => {
                disableTool(stateTool[TYPES_OF_SOCIAL_NETWORKS.TIKTOK].id).then(
                  () => {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TIKTOK });
                    invalidateQuery?.();
                  },
                );
              }}
              style={{
                width: signup ? '120px' : 'auto',
              }}>
              Disconnect
            </ButtonAdd>
          )}
        </Container>
      ) : (
        <ButtonContainer signup={signup}>
          <ButtonAdd
            signup={signup}
            bgColor={theme.colors.red}
            onClick={tiktokChecked}>
            Connect
          </ButtonAdd>
        </ButtonContainer>
      )}
    </>
  );
};

export default TiktokToogle;
