import React, { useEffect, useReducer } from 'react';
// import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import ToogleTools from '../../components/toogleTools/ToogleTools';
import { initialToolsState, toolsReducer } from '../../reducers/toolsReducer';
import {
  Title,
  Subtitle,
  Button,
  BackButton,
  BackButtondiv,
  BackButtonnext,
  ContentContainer,
  DesktopBackButtonContainer,
  // DesktopBackButton,
  // DesktopCancelButton,
  NextButtonContainer,
  // BackCancelContainer,
  ToogleToolsWrapper,
  Titlembile,
  Subtitlemobile,
} from './step3.styles';
import { useAuth } from '../../context/AuthContext';
import { notificationWithNodes } from '../../lib/notification'; // Import the notification utility
// import { socialMediaMessages } from '../steputils/steputils';

interface Step3Props {
  onNext: () => Promise<void>; // Async function for next step
  onBack: () => void; // Function for back navigation
  onCancel: () => void; // Function for canceling
  firstName?: string; // Optional firstName prop
  lastName?: string; // Optional lastName prop
}
const Step3: React.FC<Step3Props> = ({
  onNext,
  onBack,
  onCancel,
  firstName,
  lastName,
}: Step3Props) => {
  // const history = useHistory();
  const queryClient = useQueryClient();
  const { companySelected } = useAuth(); // Access your company ID from context

  // Initialize state and dispatch for tools
  const [stateTool, dispatchTool] = useReducer(toolsReducer, initialToolsState);

  useEffect(() => {
    // Display a notification when the component mounts
    // notificationWithNodes({
    //   type: 'info',
    //   title: 'Step 3',
    //   description: (
    //     <>
    //       <p>
    //       Welcome, <span style={{ color: 'red' }}>{firstName} {lastName}</span>!
    //       </p>
    //       <p>Connect your social media accounts to get started.</p>
    //     </>
    //   ),
    // });
    // Fetch user data from local storage
    const user = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');

    if (user) {
      const parsedUser = JSON.parse(user);
      // console.log('User found in localStorage:', parsedUser);

      // Extract and set the first name, last name, and email
      // const { firstName, lastName, email } = parsedUser;
    }

    // You can also set the user in your auth context
    console.log('email is ');
  }, []); // Dependencies are firstName and lastName

  return (
    <>
      <ContentContainer>
        <Title>
          Get started by connecting <br />
          your accounts
        </Title>
        <Titlembile>Get started by connecting your accounts</Titlembile>

        <Subtitle>
          Almost done! Time to connect your social media accounts. You can do
          this now for a seamless experience, or skip and set it up later. Your
          journey, your choice!
        </Subtitle>
        <Subtitlemobile>
          Ready to embark on a marketing adventure with Dinabite.ai? Let’s kick
          things off with your email and a secure password.
        </Subtitlemobile>
        <ToogleToolsWrapper backgroundColor="#fff">
          <ToogleTools
            card
            signup
            stateTool={stateTool}
            dispatchTool={dispatchTool}
            invalidateQuery={() => {
              queryClient.invalidateQueries(['MESSAGES']);
              queryClient.invalidateQueries(['TOOLS']);
            }}
            companyId={companySelected?.id}
          />
        </ToogleToolsWrapper>
      </ContentContainer>

      {/* Desktop Buttons */}
      <DesktopBackButtonContainer>
        <NextButtonContainer>
          <Button onClick={onNext}>Next Step</Button>
        </NextButtonContainer>
        {/* <BackCancelContainer>
          <DesktopBackButton onClick={onCancel}>Log Out</DesktopBackButton>
          <DesktopCancelButton onClick={onNext}>Skip all</DesktopCancelButton>
        </BackCancelContainer> */}
      </DesktopBackButtonContainer>

      {/* Mobile Buttons */}
      <BackButtondiv>
        <BackButton onClick={onNext}>Skip For later</BackButton>
        <BackButtonnext onClick={onNext}>Next</BackButtonnext>
      </BackButtondiv>
    </>
  );
};

export default Step3;
