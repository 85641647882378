import { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface TiktokWindowProps {
  auth_url?: string;
  children?: ReactNode;
}

const TiktokWindow: React.FC<TiktokWindowProps> = ({ children, auth_url }) => {
  let externalWindow: Window | null = null;

  const containerEl: HTMLDivElement = document.createElement('div');

  externalWindow = window.open('', 'tiktokWindow');

  const tiktokLogin = async () => {
    /// try {
    ///   await axios.get('tools/tiktok-login');
    /// } catch (err) {
    if (externalWindow) {
      externalWindow?.document.body.appendChild(containerEl);
      /// @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      externalWindow!.location.href = auth_url;
    }
  };

  useEffect(() => {
    tiktokLogin();
  }, []);

  return ReactDOM.createPortal(children, containerEl);
};

export default TiktokWindow;
